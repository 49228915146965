.footer__section{
    width: 100%;
    background: rgba(0, 51, 101, 1);


}

.footer__width{
    width: 80%;
    margin: auto;

}
.footer_row{
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
   padding-top: 20px;
}
.footer_coll{
    flex-basis:33%;
    padding: 20px 12px;
    box-sizing: border-box;
    transition: 0.5s;
}
.footer_coll h2{
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
    line-height: 28.8px;
    text-align: left;
color: #FFFFFF;     
}
.footer_coll h3{
    font-family: 'Nexa2',sans-serif;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 700;


}
.footer_coll p{
    margin-top: 5%;
    font-family: 'Nexa2',sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 300;
    
}
.link__footer{
    font-family: 'Nexa2',sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #F5F5F5 !important;
    display: block;
    margin-top: 9%; 
}
.contact_detil{
    display: flex;
    align-items:center;
    margin-top: 8%;
}
.contact_detil i{
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 300;  
 
}
.contact_detil span{
    font-family: 'Nexa2',sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 300;
    margin-left: 4%;   
}
.social__media{
    margin-top: 5%;
}
.social__media i{
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 300;
    padding-right: 12px;
   
}
.footer_ls{
    background: #9A742B;

}


@media (max-width:800px) {
    .footer_row{
        flex-direction: column;
        
        justify-content: space-between;
        margin-top: 2%;
       align-items:normal;  
    }  
    .footer_col h4{
    }
}