.con_sec{
    width: 80%;
    margin: auto;
}
.con_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.con_col{
    flex-basis: 49%;
}
.con_col h2{
    font-family: Manrope;
font-size: 36px;
font-weight: 700;
line-height: 46px;
text-align: left;
color: rgba(1, 19, 52, 1);
}
.con_col p{
    font-family: Manrope;
font-size: 16px;
font-weight: 400;
line-height: 26px;
text-align: left;
color: rgba(1, 19, 52, 1);
}

.con_col a{
    font-family: Manrope;
font-size: 18px;
font-weight: 700;
line-height: 26px;
text-align: left;
color: rgba(1, 19, 52, 1);
}
.for_m{
    border: 1px solid rgba(189, 189, 189, 1);
    background: rgba(255, 255, 255, 1);
    width: Fixed (686px)px;
    height: Fixed (649px)px;
    top: 638px;
    left: 678px;
    padding: 50px 0px 0px 0px;
    gap: 10px;
    border-radius: 5px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

}
.for_m1{
    width: 80%;
    margin: auto;
}
.for_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.for_col{
    flex-basis: 49%;
}
.for_col input{
    width: 100%;
}
.for_m1 input{
    width: 100%;
}
.for_m1 textarea{
    width: 100%;
}
.for_m1 button{
    width: 100%;
height: Fixed (45px)px;
padding: 12px 120px 12px 120px;
gap: 10px;
border-radius: 5px 0px 0px 0px;
opacity: 0px;
background: rgba(0, 51, 101, 1);
font-family: Manrope;
font-size: 15px;
font-weight: 600;
line-height: 20.49px;
text-align: center;
color: #ffff;

}
@media (max-width:800px) {
    .con_row,.for_row{
        flex-direction: column;
      width: 100%;
     }
    .for_m{
  width: 100%;
  margin-top: 10%;
   
    }
    .for_col{
        width: 100%;
    }
    .for_col input{
        width: 100%;
        margin-bottom: 3%;
    }
}
